import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout
      seo={{
        title: "Lena Ski - 404",
        keywords: [
          `Global thought leaders and practitioners`,
          `Education empowerment`,
          `creating, measuring and communicating brand value`,
          `support purpose-centred-entrepreneurs in their quest to greatness`
        ],
        description:
          "Coaching companies who are developing innovative and adaptive products. Giving them short-term and long-term goals to implement sustainable thinking"
      }}
      page="404"
    >
      <header className="tc ph5 portfolio-pt-ns">
        <h1 className="pt6-m pt0-l pt5 f1 f-headline-l mb3 fw9 dib lh-copy tracked-tight ski-medium-turquoise">
          404
        </h1>
        <h2 className="tc f2-l fw2 tracked">
          Sorry, we can't find the page you are looking for.
        </h2>
        <p className="fw1 tracked tc mt4 mt5-l f4 f3-l pb6-ns">
          Click on one of the links in the menu 
          <Link to="/" className="pl2 fw4 ski-medium-turquoise no-underline">
            or click here to return to the Front Page
          </Link>
        </p>
      </header>
    </Layout>
  );
};

export default NotFoundPage;
